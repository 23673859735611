<template>
  <base-material-card icon="mdi-clipboard-text" :title="$t('menu.project.list')" class="px-5 py-3">
    <base-add-button :permissions="permissions" :basePath="basePath" :title="$t('project.add')">
    </base-add-button>
    <v-simple-table v-if="items && items.length > 0">
      <thead>
        <tr>
          <th class="text-center primary--text">
            {{ $t('general.actions') }}
          </th>
          <!--          <th class="primary&#45;&#45;text">-->
          <!--            ID-->
          <!--          </th>-->
          <th class="primary--text">
            {{ $t('project.projectName') }}
          </th>
          <th class="primary--text">
            {{ $t('project.projectDesc') }}
          </th>
          <th class="primary--text">
            {{ $t('project.developerName') }}
          </th>
          <th class="primary--text">
            {{ $t('project.constructionStatus') }}
          </th>
          <th class="primary--text">
            {{ $t('project.impression') }}
          </th>
          <th class="primary--text">
            {{ $t('project.visit') }}
          </th>
          <th class="primary--text">
            {{ $t('project.lead') }}
          </th>
          <th class="text-center primary--text">
            {{ $t('general.openDetail') }}
          </th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="item in items" :key="item.uuid">
          <td>
            <div class="d-flex justify-center align-center">
              <base-edit-button
                :permissions="permissions"
                :basePath="basePath"
                :id="item.uuid"
              ></base-edit-button>
              <base-delete-button
                :permissions="permissions"
                :id="item.uuid"
                :showIcon="true"
                @deleteAction="deleteAction"
              ></base-delete-button>
            </div>
          </td>
          <!--          <td>{{ index + 1 }}</td>-->
          <td>{{ item.project_name }}</td>
          <td>{{ shortString(stripHtml(item.project_desc)) }}</td>
          <td>{{ item.developer_name }}</td>
          <td>{{ item.construction_status }}</td>
          <td>{{ item.impression_amount }}</td>
          <td>{{ item.visit_amount }}</td>
          <td>{{ item.lead_amount }}</td>
          <td>
            <base-detail-button
              :permissions="permissions"
              :basePath="basePath"
              :id="item.uuid"
            ></base-detail-button>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <pagination
      :push-state="false"
      :meta="meta"
      @changePage="changePage"
      :maxVisibleButtons="5"
      v-if="meta"
    />
  </base-material-card>
</template>

<script>
import { mapState } from 'vuex';
import HelperMixin from '@/mixins/helpers';
const Pagination = () => import('@/components/utils/fractal-pagination');

export default {
  mixins: [HelperMixin],
  props: ['items', 'meta', 'permissions'],
  components: {
    Pagination,
  },
  computed: {
    ...mapState({
      basePath: (state) => state.project.basePath,
    }),
  },
  methods: {
    changePage(page) {
      this.$emit('changePage', page);
    },
    async deleteAction(uuid) {
      console.log('DELETE PROJECT UUID: ', uuid);
      try {
        await this.$store.dispatch('project/delete', uuid);
        await this.$store.dispatch('project/removeProjectListByUuid', uuid);
        this.$swal(
          this.$i18n.t('general.success'),
          this.$i18n.t('project.delete.successMsg'),
          'success',
        );
        await this.$store.dispatch('project/getInitData', this.$route.query);
        // eslint-disable-next-line no-empty
      } catch (e) {}
    },
  },
};
</script>
